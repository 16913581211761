<template>
    <div class="">
       <img width="100%" height="100%" src="@/assets/img/index/wxpage.png" alt="">
    </div>
  </template>
  
  <script>
  
    export default {
      data() {
        return {
        }
      },
      components: {
      },
      created() {
  
      },
      mounted() {
      },
      beforeDestroy(){
      },
      methods:{
        
      },
    };
  </script>
  <style lang="scss" scoped>
   .content{
    height: 50vh
   }
  </style>
  